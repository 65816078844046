var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"number-condition-setup-view"},[_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'numberValue',
        'prefix': _vm.triggerPrefix,
        'validation': 'required',
        'disabled': _vm.disabled,
        'min': typeof _vm.min === 'number' ? _vm.min : Number.MIN_SAFE_INTEGER,
        'max': typeof _vm.max === 'number' ? _vm.max : Number.MAX_SAFE_INTEGER
      }
    }}}),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }