import { render, staticRenderFns } from "./CheckBindedChannelSetupView.vue?vue&type=template&id=8b8d48da&scoped=true&"
import script from "./CheckBindedChannelSetupView.vue?vue&type=script&lang=ts&"
export * from "./CheckBindedChannelSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b8d48da",
  null
  
)

export default component.exports