
































































































































































































































import {
  ConditionZone,
  NewTriggerCondition,
  OldTriggerCondition,
} from "@/components/TriggerSetup/logic/types/types";
import { TriggerFactory } from "@/components/TriggerSetup/logic/trigger/trigger-factory";
import CreateCondition from "@/components/TriggerSetup/components/CreateCondition/CreateCondition.vue";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { conditionsGroupsTypes, newConditionWrappers } from "../../conditions";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import '@/includes/validators/placeholder_validator'
import { getConditionZoneIcon, getConditionZoneTitle } from "@/components/TriggerSetup/components/ConditionZone/helpers";
import ConditionZoneField from "@/components/TriggerSetup/components/ConditionZoneField.vue";

import BlockMovingControls from "piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue";
import { ListDirection } from "piramis-base-components/src/components/Pi/components/List/logic/types";
import List from "piramis-base-components/src/components/Pi/components/List/List.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import { Item } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { cloneDeep, memoize } from "lodash";
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { ValidationObserver } from "vee-validate";

type CurrentActionInfo = {
  parentGuid: string | null
  condition: IterableListItem<EntityData> | null
}

@Component({
  'components': {
    ConditionZoneField,
    ActionChance,
    CreateCondition,
    ConfigField,
    BlockMovingControls,
    List,
    TextInput,
    ValidationObserver,
    Icon
  },

  'data': () => ({
    ListDirection,
  }),

  'methods': {
    getConditionZoneIcon,
    getConditionZoneTitle,
  }
})
export default class ConditionsGroups extends Mixins(ModelSetter) {
  @VModel({ 'type': Array, 'required': true }) model!: Array<IterableListItem<Array<IterableListItem<EntityData>>>>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Boolean, required: false, default: true }) checkLicense!: boolean

  triggerFactory: TriggerFactory = new TriggerFactory()

  currentActionInfo: CurrentActionInfo = this.initCurrentActionState()

  isConditionExtraSettingModalOpen = false

  get emptyConditionsAlert() {
    if (this.$store.getters.isChatSet) {
      return this.$t('triggers_empty_trigger_alert_chat').toString()
    }

    return this.$t('triggers_empty_trigger_alert_network').toString()
  }

  initCurrentActionState(): CurrentActionInfo {
    return {
      parentGuid: null,
      condition: null
    }
  }

  prepareCurrentConditionExtraSettings(parentGuid: CurrentActionInfo['parentGuid'], actionModel: CurrentActionInfo['condition']) {
    this.currentActionInfo.parentGuid = parentGuid
    this.currentActionInfo.condition = cloneDeep(actionModel)

    this.isConditionExtraSettingModalOpen = true
  }

  setTriggerConditionModel() {
    const { parentGuid, condition } = this.currentActionInfo

    if (parentGuid && condition) {
      const group = this.model.find(g => g.guid === parentGuid)

      if (group) {
        const groupCondition = group.value.find(a => a.guid === condition.guid)

        if (groupCondition) {
          groupCondition.value.structure.placeholder = condition.value.structure.placeholder
        }
      }
    }

    this.isConditionExtraSettingModalOpen = false
  }

  updateCondition(condition: IterableListItem<EntityData>, newCondition: EntityData | undefined) {
    const copy = cloneDeep(condition)

    if (newCondition) {
      condition.value = newCondition

      condition.value.structure.condition_zone = copy.value.structure.condition_zone
    }
  }

  conditionWrapper = memoize((condition: OldTriggerCondition | NewTriggerCondition) => {
    const conditionType = condition.version === 'v1' ? condition.type : condition.condition_type

    return newConditionWrappers.find(w => w.extraProps?.conditions?.some((c: EntityData) => c.structure.condition_type === conditionType))
  })

  removeConditionGroup(index: number): void {
    this.$emit('removeConditionGroup', this.model[index].guid)
    this.model = this.model.filter((_, filteredIndex) => filteredIndex !== index)
  }

  createCondition(conditionGroup: IterableListItem<Array<IterableListItem<EntityData>>>, conditionData: { key: Item["key"], zone: ConditionZone | null } ): void {
    const condition: any | undefined = Object.values(conditionsGroupsTypes).flat().find(e => e.key === conditionData.key)

    if (condition) {
      if (conditionData.zone) {
        condition.structure.condition_zone = conditionData.zone
      }

      conditionGroup.value.push(this.triggerFactory.createCondition(condition.structure))
    }
  }

  addConditionsGroup(conditionsGroups: Array<IterableListItem<Array<IterableListItem<EntityData>>>>): void {
    conditionsGroups.push(this.triggerFactory.createConditionsGroup())
  }
}
