var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('number-input',{attrs:{"setup":{
    'func': _vm.modelSetter,
    'args': {
      model: _vm.model,
      'key': 'chance',
      'prefix': 'trigger_action_',
      'min': 0,
      'max': 100,
      disabled: _vm.disabled,
      'validation': 'required',
      'formatter': function (value) { return (value + "%"); },
      'parser': function (value) { return value.replace('%', ''); },
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }