var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"condition-users-settings"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.conditionData,
        'key': 'caller',
        'prefix': 'trigger_condition_',
        'options': _vm.caller,
        'clearable': false,
        'validation': 'required',
        'disabled': _vm.disabled
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.conditionData,
        'key': 'messageType',
        'prefix': 'trigger_condition_',
        'options': _vm.messageType,
        'clearable': false,
        'validation': 'required',
        'disabled': _vm.disabled
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }