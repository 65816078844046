import { render, staticRenderFns } from "./ActionUsers.vue?vue&type=template&id=39f296b2&scoped=true&"
import script from "./ActionUsers.vue?vue&type=script&lang=ts&"
export * from "./ActionUsers.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f296b2",
  null
  
)

export default component.exports