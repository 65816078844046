var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-trigger-active-state-action"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        disabled: _vm.disabled,
        'key': 'triggerKey',
        'prefix': 'trigger_action_',
        'options': _vm.getTaggedTriggerOptions,
        'validation': 'required',
        'clearable': false,
      }
    }}}),_c('config-field',{attrs:{"title":_vm.$t('field_trigger_action_state_title'),"inline":""}},[_c('toggle-button',{attrs:{"disabled":_vm.disabled,"unchecked-title":_vm.$t('disable'),"checked-title":_vm.$t('enable')},model:{value:(_vm.model.state),callback:function ($$v) {_vm.$set(_vm.model, "state", $$v)},expression:"model.state"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }