var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-condition-setup-view"},[(_vm.$te(_vm.conditionDescriptionKey))?_c('a-alert',{attrs:{"show-icon":""},domProps:{"innerHTML":_vm._s(_vm.$t(_vm.conditionDescriptionKey))}}):_vm._e(),_c('chips-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.conditionData,
        'key': 'value',
        'prefix': ("trigger_condition_" + (_vm.type ? _vm.type + '_': '')),
        'validation': 'required',
        'disabled': _vm.disabled
      }
    }}}),_c('condition-filters-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}}),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }