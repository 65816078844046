import { render, staticRenderFns } from "./DeleteAllMessageActionSetupView.vue?vue&type=template&id=e8f1022a&scoped=true&"
import script from "./DeleteAllMessageActionSetupView.vue?vue&type=script&lang=ts&"
export * from "./DeleteAllMessageActionSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8f1022a",
  null
  
)

export default component.exports