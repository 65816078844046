var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reaction-remove-condition-setup-view"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.conditionData,
        'key': 'value',
        'prefix': 'trigger_condition_reaction_add_',
        'options': _vm.emojiOptions,
        'multiple': true,
        'disabled': _vm.disabled,
        'settingWrapper': {
          'hasFieldButton': true,
          'customiseValue': _vm.emojiOptions[0],
          'value': [{ value: '' }],
        },
      }
    }}}),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }