


















import TriggerSetup from '@/components/TriggerSetup/TriggerSetup.vue'
import { RawTriggerData, TriggerData } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/trigger/trigger-factory'
import ChatApi from "@/includes/Api/Chat.api";
import TriggerControls from '@/components/TriggerSetup/components/TriggerControls/TriggerControls.vue'
import VuTriggers from '@/includes/logic/VuTriggers'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/NotificationService'
import { BaseViewActionType } from '@/includes/types/Enums'
import TriggersApi from "@/includes/Api/Triggers.api";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep, isEqual } from 'lodash'
import { Route } from 'vue-router'
import { NavigationGuardNext } from 'vue-router/types/router'

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  'components': {
    TriggerSetup,
  },
  data() {
    return {
      TriggerControls,
    }
  }
})
export default class Trigger extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  triggerModel: TriggerData | null = null

  @Watch('triggerModel', { deep: true })
  onTriggerModelChange() {
    this.defaultTriggerAction = false
  }

  triggerFactory: TriggerFactory = new TriggerFactory()

  triggerBackup: TriggerData | null = null

  defaultTriggerAction = false

  isTriggerValid = false

  get canChangeConfig() {
    return this.$store.getters.canChangeConfig
  }

  get chatId(): number {
    return Number.parseInt(this.$route.params[EntityTypes.CHAT_ID])
  }

  get triggerId() {
    return this.$route.params.triggerId.toString()
  }

  get triggerSetupType(): BaseViewActionType {
    return this.$route.params.actionType as BaseViewActionType
  }

  get controlsListeners(): any {
    const listeners: any = {}

    listeners['save'] = this.handleSave
    listeners['save-no-move'] = this.handleSaveNotMove

    if (this.triggerSetupType === BaseViewActionType.Edit) {
      listeners['remove'] = this.handleRemove
      listeners['export-trigger'] = this.exportTrigger
    }

    return listeners
  }

  exportTrigger() {
    ChatApi.exportChatTriggers('tg', {
      chat_id: this.$store.getters.chatId,
      triggers: [ this.triggerId ]
    })

    successNotification()
  }

  async handleSaveNotMove(rawTriggerData: RawTriggerData): Promise<void> {
    try {
      if (this.triggerSetupType === BaseViewActionType.Edit) {
        rawTriggerData.id = this.triggerId
      }

      this.$baseTemplate.loader.open()

      const { data } = await VuTriggers.setTrigger(rawTriggerData)

      this.$store.commit('enableTrigger', data.id)
      this.$store.dispatch('requestChatConfig', { id: this.chatId })

      this.defaultTriggerAction = true

      successNotification()

      if (this.$route.params.actionType === 'new') {
        this.$router.replace({
          name: 'chat_trigger',
          params: {
            [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
            actionType: 'edit',
            triggerId: data.id!
          },
        })
      }
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  async handleSave(rawTriggerData: RawTriggerData, redirect = true): Promise<void> {
    try {
      this.defaultTriggerAction = true
      window.removeEventListener('beforeunload', this.beforeWindowUnload)

      const data = await this.saveTrigger(rawTriggerData)

      if (data) {
        this.$store.commit('enableTrigger', data.id)
        this.$store.commit('updatedTriggerId', data.id)

        this.$store.dispatch('requestChatConfig', { id: this.chatId })

        if (redirect) {
          await this.$router.push({
            'name': 'chat_triggers',
          })
        }
      }
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  async saveTrigger(rawTriggerData: RawTriggerData):Promise<RawTriggerData | undefined> {
    try {
      if (this.triggerSetupType === BaseViewActionType.Edit) {
        rawTriggerData.id = this.triggerId
      }

      this.$baseTemplate.loader.open()

      const { data } = await VuTriggers.setTrigger(rawTriggerData)

      return data
    } catch (error) {
      errorNotification(error)
    } finally {
      this.$baseTemplate.loader.close()
    }
  }

  async handleRemove(): Promise<void> {
    try {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)

      await VuTriggers.deleteFrontendTrigger(this.triggerId)
      this.$store.dispatch('requestChatConfig', { id: this.chatId })
      await this.$router.push({
        name: 'chat_triggers',
      })
    } catch (error) {
      errorNotification(error)
    }
  }

  triggerNameIfQuery():string {
    const triggerName = this.$route.query.name

    if (triggerName) return triggerName.toString()
    return ''
  }

  createNewTriggerFromExists(id: string) {
    return TriggersApi.getTrigger(id)
      .then(({ data }) => this.triggerFactory.createNewTriggerFromExists(data.trigger))
      .catch(errorNotification)
  }

  async created(): Promise<void> {
    const chatId = this.chatId
    let triggerData: TriggerData | null = null

    if (this.triggerSetupType === BaseViewActionType.New) {
      if (this.$store.state.triggersState.newTrigger) {
        triggerData = this.triggerFactory.createNewTriggerFromExists(this.$store.state.triggersState.newTrigger)
        this.$store.commit('resetNewTrigger')
      } else {
        triggerData = this.triggerFactory.createNewTrigger({ chatId })
        triggerData.name = this.triggerNameIfQuery()
      }
    }

    if (this.triggerSetupType === BaseViewActionType.Edit) {
      if (this.triggerId) {
        triggerData = await this.createNewTriggerFromExists(this.triggerId) ?? null
      }
    }

    if (!triggerData) {
      this.$router.push({ name: 'chat_triggers' })
    }

    this.triggerBackup = cloneDeep(triggerData)
    this.triggerModel = triggerData || null
  }

  beforeWindowUnload(e:BeforeUnloadEvent) {
    if(!isEqual(this.triggerBackup, this.triggerModel)) {
      return e.returnValue = this.$t('leave_trigger_setting_warn_text').toString();
    }
  }

  beforeRouteLeave(to: Route, from:Route, next:NavigationGuardNext): void {
    if(!isEqual(this.triggerBackup, this.triggerModel) && !this.defaultTriggerAction) {
      const content = this.isTriggerValid
        ? this.$t('leave_trigger_setting_warn_text').toString()
        : this.$t('leave_trigger_setting_warn_invalid_text').toString()

      const okText = this.isTriggerValid
        ? this.$t('save_and_leave').toString()
        : this.$t('fix_setting').toString()

      this.$confirm({
        title: this.$t('warning').toString(),
        content,
        okText,
        cancelText: this.$t('leave').toString(),
        onOk: () => {
          next(false)
          if (this.isTriggerValid) {
            this.handleSave(this.triggerFactory.triggerDataToRawTriggerData(this.triggerModel!), false)
              .then(() => {
                next()
              })
          }
        },
        onCancel: () => {
          next()
        }
      })
    } else {
      next()
    }
  }

  mounted():void {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  }

  destroyed():void {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  }
}
