var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pin-message-action-setup-view"},[_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'delay',
        'prefix': 'pin_message_action_',
        'settingWrapper': {
          value: 0,
          hasFieldButton: true,
          customiseValue: 10,
        }
      }
    }}}),_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'notify',
        'prefix': 'pin_message_action_',
      }
    }}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'reply',
        'prefix': 'pin_message_action_',
        'options': _vm.replyOptions,
        'clearable': false
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }