import { render, staticRenderFns } from "./AddMessageReactionActionSetupView.vue?vue&type=template&id=35300c33&scoped=true&"
import script from "./AddMessageReactionActionSetupView.vue?vue&type=script&lang=ts&"
export * from "./AddMessageReactionActionSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35300c33",
  null
  
)

export default component.exports