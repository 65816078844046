var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-warn-action-wrapper"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'sendWarnMessage',
        'prefix': 'trigger_action_',
      }
    }},on:{"change":_vm.onChange}}),_c(_vm.viewBySendWarnMessage.setupView,{tag:"component",model:{value:(_vm.model.currentAction),callback:function ($$v) {_vm.$set(_vm.model, "currentAction", $$v)},expression:"model.currentAction"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }