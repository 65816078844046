






































import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";

import UsersInfoMixin from "piramis-base-components/src/shared/logic/getUsersInfo/UsersInfoMixin"
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { FieldData } from "piramis-base-components/src/components/Pi/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class ActionUsers extends Mixins(ActionSetupView, UsersInfoMixin) {

  usersInfoService = new GetUserInfoServiceImpl(this.$store.getters.chatId)

  get receivedAdministratorsMapSelectOptions(): Array<SelectOption> {
    if (this.$store.getters.isChatSet) {
      return Object.entries(this.$store.state.chatState.chat!.received_administrators_map)
        .map(([ id, name ]) => {
          return {
            label: name,
            value: id
          }
        })
    } else {
      return []
    }
  }

  get receivedAdministratorsMapValues() {
    if (this.$store.getters.isChatSet) {
      return Object.values(this.$store.state.chatState.chat!.received_administrators_map)
    } else {
      return []
    }
  }

  userInfoSetter(args: FieldData) {
    args.setter = (value) => this.userInfoModelSetter(args, { model: value }, this.model.users, this.usersInfoService)

    return args
  }

  format(tag: string | null) {
    return this.formatter(this.usersInfoService.usersInfos, tag)
  }

  created() {
    this.usersInfoService.getUsersInfos(this.model.users)
  }
}
