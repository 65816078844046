var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-interval"},[_c('action-targets',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('checkbox-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model'     : this,
        'key'       : 'isShowIntervalVisible',
        'validation': 'required',
      }
    }}}),(!_vm.isShowIntervalVisible)?_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'computedCount',
        'prefix': 'trigger_action_',
        disabled: _vm.disabled,
        'min': Number.MIN_SAFE_INTEGER,
        'validation': 'required',
      }
    }}}):_vm._e(),(_vm.isShowIntervalVisible)?_c('nested-content',[_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'from',
          'prefix': 'trigger_action_',
          disabled: _vm.disabled,
          'min': Number.MIN_SAFE_INTEGER,
          'validation': 'required',
        }
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'to',
          'prefix': 'trigger_action_',
          disabled: _vm.disabled,
          'min': Number.MIN_SAFE_INTEGER,
          'validation': 'required',
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }